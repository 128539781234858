// @ngInject
function acpDirectDepositClient(webapiResource) {
  var service = {};
  var getDD = webapiResource({
    method: 'GET',
    path: '/v1/directdeposit',
    cache: true
  });

  service.get = function() {
    return getDD();
  };

  service.getAccountAndRoutingInfo = function() {
    return service.get().then(function(data) {
      return {
        account_number: data.account_number,
        routing_number: data.routing_number,
        bank: data.bank
      };
    });
  };

  return service;
}

export default acpDirectDepositClient;
