import acpDirectDepositPanelSimpleTemplate from './templates/acp-direct-deposit-panel-simple.html';
//@ngInject
function acpDirectDepositPanelSimpleDirective() {
  return {
    restrict: 'E',
    scope: {},
    template: acpDirectDepositPanelSimpleTemplate,
    controller: 'acpDirectDepositPanelSimpleCtrl'
  };
}

export default acpDirectDepositPanelSimpleDirective;
