// @ngInject
function AcpDirectDepositPanelCtrl(
  $scope,
  acpDirectDepositModel,
  acpDeviceService,
  acpCoreDispatcher,
  nsConfig,
  content,
  acpCordovaLinksClient
) {
  function init() {
    $scope.$tiePermissions('permissions', ['hasDirectDepositAvailable']);
    $scope.$tie('ddInfo', acpCoreDispatcher.account.directDepositInfo);
    load();
    $scope.emailSubject = 'My Direct Deposit Information';
    $scope.productName = '';
    // $scope.showEmailButton = SHOW_EMAIL_DD_INFO === 'true' ? true : false;
  }

  function load() {
    acpDirectDepositModel.fetchDDInformation();
    content.getKey('product-name-short', 'core/variables').then(function(data) {
      $scope.productName = data.copy;
    });
  }

  $scope.showEmailInformationButton = function() {
    return (
      !acpDeviceService.isWeb() && nsConfig.get('showEmailInformationButton')
    );
  };

  $scope.getEmailBody = function() {
    var an = '',
      rn = '';

    if ($scope.ddInfo) {
      if ($scope.ddInfo.account_number) {
        an = $scope.ddInfo.account_number;
      }
      if ($scope.ddInfo.routing_number) {
        rn = $scope.ddInfo.routing_number;
      }
    }
    //todo: replace brand with GPR_CARDNAME
    return (
      'This is the Direct Deposit information for my ' +
      $scope.productName +
      ' account:%0A%0AAccount Number: ' +
      an +
      '%0ARouting Number: ' +
      rn
    );
  };

  $scope.openEmail = function() {
    acpCordovaLinksClient.openExternalEmail(
      'mailto:?subject=' +
        $scope.emailSubject +
        '&body=' +
        $scope.getEmailBody()
    );
  };

  init();
}

export default AcpDirectDepositPanelCtrl;
