import acpDirectDepositPanelTemplate from './templates/acp-direct-deposit-panel.html';
// @ngInject
function acpDirectDepositPanelDirective() {
  return {
    controller: 'AcpDirectDepositPanelCtrl',
    restrict: 'E',
    scope: {},
    template: acpDirectDepositPanelTemplate
  };
}

export default acpDirectDepositPanelDirective;
