// @ngInject
function AcpDirectDepositInfoPanelCtrl($scope) {
  function init() {
    $scope.$tiePermissions('permissions', ['hasDirectDepositAvailable']);
  }

  init();
}

export default AcpDirectDepositInfoPanelCtrl;
