import acpDirectDepositExpandablePanelTemplate from './templates/acp-direct-deposit-expandable-panel.html';
// @ngInject
function acpDirectDepositPanelDirective() {
  return {
    controller: 'AcpDirectDepositExpandablePanelCtrl',
    restrict: 'E',
    scope: {
      title: '@'
    },
    template: acpDirectDepositExpandablePanelTemplate
  };
}

export default acpDirectDepositPanelDirective;
