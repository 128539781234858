import directDepositContent from './direct-deposit-content.yml';
import acpDirectDepositPanelSimpleCtrl from './acp-direct-deposit-panel-simple-ctrl';
import acpDirectDepositExpandablePanelCtrl from './acp-direct-deposit-expandable-panel-ctrl';
import acpDirectDepositInfoPanelCtrl from './acp-direct-deposit-info-panel-ctrl';
import acpDirectDepositPanelCtrl from './acp-direct-deposit-panel-ctrl';
import acpDirectDepositPanelSimpleDirective from './acp-direct-deposit-panel-simple-directive';
import acpDirectDepositExpandablePanelDirective from './acp-direct-deposit-expandable-panel-directive';
import acpDirectDepositPanelDirective from './acp-direct-deposit-panel-directive';
import acpDirectDepositModel from './acp-direct-deposit-model';
import acpDirectDepositClient from './acp-direct-deposit-client';
import acpComponentAnimatedDropdownArrow from 'components/animated-dropdown-arrow';
import acpCore from 'core';
import ng from 'angular';
import './styles/${theme}/core.scss';
var component = ng
  .module('acp.component.direct-deposit', [
    acpCore.name,
    acpComponentAnimatedDropdownArrow.name
  ])

  .factory('acpDirectDepositClient', acpDirectDepositClient)
  .factory('acpDirectDepositModel', acpDirectDepositModel)

  .directive('acpDirectDepositPanel', acpDirectDepositPanelDirective)
  .directive(
    'acpDirectDepositExpandablePanel',
    acpDirectDepositExpandablePanelDirective
  )
  .directive(
    'acpDirectDepositPanelSimple',
    acpDirectDepositPanelSimpleDirective
  )

  .controller('AcpDirectDepositPanelCtrl', acpDirectDepositPanelCtrl)
  .controller('AcpDirectDepositInfoPanelCtrl', acpDirectDepositInfoPanelCtrl)
  .controller(
    'AcpDirectDepositExpandablePanelCtrl',
    acpDirectDepositExpandablePanelCtrl
  )
  .controller(
    'acpDirectDepositPanelSimpleCtrl',
    acpDirectDepositPanelSimpleCtrl
  )
  .run(
    /*@ngInject*/ function(contentSectionCache) {
      contentSectionCache.put(
        'components/direct-deposit',
        directDepositContent
      );
    }
  );

export default component;
