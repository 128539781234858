// @ngInject
function AcpDirectDepositExpandablePanelCtrl($scope) {
  function init() {
    $scope.$tiePermissions('permissions', ['hasDirectDepositAvailable']);
    $scope.expanded = false;
  }

  $scope.toggle = function() {
    $scope.expanded = !$scope.expanded;
  };

  init();
}

export default AcpDirectDepositExpandablePanelCtrl;
