// @ngInject
function acpDirectDepositModel(acpCoreDispatcher, acpDirectDepositClient) {
  var model = {};

  /**
   * I provide methods for interacting with web-api's /directdeposit service.
   */
  model.fetchDDInformation = function() {
    acpDirectDepositClient.getAccountAndRoutingInfo().then(function(data) {
      // Assume Checking account type.
      // TODO: update API to return account type.
      data.account_type = 'Checking';
      acpCoreDispatcher.account.directDepositInfo.set(data);
    });
  };

  return model;
}

export default acpDirectDepositModel;
