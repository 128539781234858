//@ngInject
function acpDirectDepositPanelSimpleCtrl(
  $scope,
  acpCoreDispatcher,
  acpDirectDepositModel
) {
  $scope.$tie('ddInfo', acpCoreDispatcher.account.directDepositInfo);
  acpDirectDepositModel.fetchDDInformation();
}

export default acpDirectDepositPanelSimpleCtrl;
